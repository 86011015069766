// Afrikaans
export default{
    form: {
        startSurveyButton: "Kom ons begin",
        nextButton: "Volgende",
        submitAnswerButton: "Dien antwoord in",
        continueButton: "Gaan voort",
        finishButton: "Voltooi",
        headerPercentageCompletion: 'voltooi',
        headerQuestionsCompleted: 'vrae',
        headerDefaultErrorMessage: 'Oeps! Iets het verkeerd geloop.',
        brandingPoweredBy: 'Aangedryf deur',
        brandingPromoWhySurvey: 'Hoekom ’n opname doen as jy <b>{product}</b> kan gebruik?',
        brandingPromoGetStarted: 'Begin vandag!',
        submissionPending: "Jou voiceform word ingedien. Moet asseblief nie die blaaier sluit nie.",
        submissionCanCloseWindow: "Jou voiceform is suksesvol ingedien. Jy kan nou die venster toemaak 👍",
        mediaAttachmentOverlayCloseButton: "Maak toe",
        expiredFormHeader: "Dankie dat jy gekom het!",
        expiredFormMessage: "Dit lyk of hierdie opname verval het. Kontak asseblief die voiceform-skepper om hulle in te lig.",
        notPublishedFormHeader: "Die voiceform is nie gepubliseer nie!",
        notPublishedFormMessage: "Dit lyk of hierdie opname nie gepubliseer is nie. Kontak asseblief die voiceform-skepper om hulle in te lig.",
        notPublishedFormRetryButton: "Probeer weer",
        errorFormHeader: "Oeps!",
        errorFormMessage: "Iets het dalk verkeerd gegaan. Probeer asseblief later weer.",
        errorFormRetryButton: "Probeer weer",
        emptyFormHeader: "Dankie dat jy gekom het!",
        emptyFormMessage: "Oeps! Dit lyk of hierdie vorm tans leeg is sonder enige vrae of velde om in te vul. Ons vra omverskoning vir die ongerief. Kontak asseblief die skepper van hierdie vorm om hulle in te lig oor die probleem. Dankie vir jou begrip en samewerking!",

        restoreFormHeader: "Dit lyk of jy ’n onvoltooide vorm het!",
        restoreFormMessage: "Wil jy voortgaan waar jy opgehou het?",
        restoreFormButton: "Gaan voort met vorm",
        restoreFormButtonNew: "Begin van voor af",

        audioPermissionHeader: "Wil jy met jou stem antwoord?",
        audioPermissionMessage: "Praat maak die antwoord vinnig en maklik.",
        audioPermissionAcceptButton: "Ja, ek wil",
        audioPermissionDenyButton: "Nee, dankie",
        audioPermissionErrorHeader: "Mikrofoon is nie beskikbaar nie",
        audioPermissionErrorMessage: "Kontroleer asseblief jou blaaierinstellings om toegang tot die mikrofoon te aktiveer.",
        audioPermissionErrorButton: "OK",
        audioRecorderFailedUpload: "Oplaai van opname het misluk",
        silenceDetectorError: "Ons kan jou nie hoor nie. Kontroleer asseblief jou mikrofooninstellings. Wenkwys: Dit kan jou eksterne mikrofoon wees.",
        audioRecorderButtonRecord: "Begin opname",
        audioRecorderButtonRecordMore: "Neem meer op",
        voiceResponseSelectionMessage: "Kies hoe jy wil antwoord...",
        voiceResponseSelectionOrDivider: "of",
        voiceResponseTextInputPlaceholder: "Tik antwoord",
        voiceResponseTextInputCharacterCounterMinMessage: "Voer asseblief ten minste {min} karakters in",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} karakters (minimum {min} karakters)",
        voiceResponseTextInputCharacterValidationMessage: "{count} karakters (minimum {min} karakters)",

        // checkbox
        checkboxValidationTooFew: "Jy moet ten minste {min} opsie(s) kies",
        checkboxValidationTooMany: "Jy mag nie meer as {max} opsie(s) kies nie",
        checkboxNoneOfTheAboveOption: "Geen van bogenoemde nie",
        checkboxInvalidAnswer: "Ongeldige antwoord.",

        // datepicker
        datePickerPlaceholder: "Kies datum",

        // dropdown
        // email
        emailLabel: "E-pos",
        emailInvalid: "Ongeldige e-pos.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Neem video op",
        fileUploadPluginScreenCast: "Skermopname",
        fileUploadVideoImportFilesDefault: 'Neem of laai ’n video op via:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Kies ’n video-opname opsie:',
        fileUploadVideoImportFilesNoCamera: 'Druk ’n knoppie om ’n skermvideo op te neem',
        fileUploadVideoImportFilesNoScreenCast: 'Druk ’n knoppie om ’n video op te neem',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Geen opsies beskikbaar om video op te neem nie',

        // matrix
        matrixValidationMessage: "Die antwoord is geldig.",
        matrixRow: "Ryg",
        matrixColumn: "Kolom",
        matrixRowRequired: "Die ryg {rowTitle} is verpligtend.",
        matrixRadioGroup: "Radio-groep",
        matrixCheckboxGroup: "Merkblokkie-groep",
        matrixGroupRequired: "Vir die ryg {rowTitle}. {type} {groupTitle} is verpligtend.",
        matrixColumnRequired: "Vir die ryg {rowTitle}. Die kolom {columnTitle} is verpligtend.",
        matrixColumnInvalid: "Vir die ryg {rowTitle}. Die kolom {columnTitle} is ongeldig.",
        matrixRequired: "Verpligtend.",
        matrixNumericMustBeNumber: "Moet ’n nommer wees.",
        matrixNumericMustBeGreaterThenMin: "Moet groter as {min} wees.",
        matrixNumericMustBeLessThenMax: "Moet kleiner as {max} wees.",
        matrixNumericMustBeInteger: "Moet ’n heelgetal wees.",
        matrixNumericInvalidNumber: "Ongeldige nommer.",

        // name
        nameLabel: "Naam",
        nameInvalid: "Gee asseblief voor- en van.",

        // nps
        npsNotLikely: "Glad nie waarskynlik nie",
        npsExtremelyLikely: "Baie waarskynlik",

        // numeric input
        numericInputRequired: "Verpligtend.",
        numericInputMustBeNumber: "Moet ’n nommer wees.",
        numericInputMustBeGreaterThenMin: "Moet groter as {min} wees.",
        numericInputMustBeLessThenMax: "Moet kleiner as {max} wees.",
        numericInputMustBeInteger: "Moet ’n heelgetal wees.",
        numericInputInvalidNumber: "Ongeldige nommer.",
        numericInputPlaceholder: "Tik nommer",

        // phone
        phoneInvalid: "Die telefoonnommer is ongeldig.",
        phoneCountrySelectorLabel: 'Landelike kode',
        phoneCountrySelectorError: 'Kies land',
        phoneNumberLabel: 'Telefoonnommer',
        phoneExample: 'Voorbeeld:',

        // boolean
        booleanYesLabel: "Ja",
        booleanNoLabel: "Nee",

        //questionStep
        questionStepAudioQuestionLabel: "Klankvraag",

        // errors
        invalidPhoneNumber: "{phone} is ongeldig telefoonnommer.",
        invalidEmail: "{email} is ongeldig e-posadres.",
        questionIsRequired: "Die vraag is verpligtend.",
        answerIsNotValid: "Die antwoord is ongeldig.",
        unfinishedProbingDialogError: "Maak asseblief die dialoog klaar.",
        cannotResumePartialResponse: "Kan nie gedeeltelike antwoord hervat nie.",
        failedToSubmitForm: "Kon nie die vorm indien nie. Kyk jou internetverbinding en probeer weer."
    }
}
